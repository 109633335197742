<template>
  <div>
    <content-template
      :menuName="$t('sidebar.expertRequest')"
      :backButton="true"
      @backToPage="moveToOther('Dashboard CMS')"
    >
      <template #form>
        <form>
          <div class="s-mb-20">
            <Input
              v-model="edited_item.institution"
              :label="$t('general.institution')"
              class="s-mb-20"
              :error="validation.institution"
            />

            <Input
              v-model="edited_item.position"
              :label="$t('general.position')"
              class="s-mb-20"
              :error="validation.position"
            />

            <label class="s-input-label">{{ $t("expert.expertise") }}</label>
            <Multiselect
              v-model="edited_item.expertise_detail"
              tag-placeholder=""
              placeholder="Search or add a expertise"
              label="name"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.expertise_detail">
              {{ $t("general.fillThisField") }}
            </span>
          </div>

          <p class="s-text-primary s-weight-600">
            {{ $t("general.upload") }} CV (PDF)
          </p>
          <div class="s-pb-30 s-position-relative">
            <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
              <div>
                <img
                  :src="require('@/assets/icons/primary/cloud_upload.png')"
                />
                <p>{{ $t("general.dragAndDrop") }}</p>
                <p>{{ $t("general.or") }}</p>
                <Button>{{ $t("general.uploadFile") }}</Button>
              </div>
            </div>

            <input
              v-if="!fileDoc.name"
              id="document"
              ref="document"
              class="s-hide"
              type="file"
              @change="importData('document')"
              accept="application/pdf"
              multiple
            />
            <div class="s-position-relative s-fullWidth s-center-flex">
              <div
                class="
                  s-center-flex
                  s-mb-16
                  s-bg-green-40
                  s-plr-10
                  s-radius-10
                  s-fullWidth
                "
                v-if="fileDoc.name"
              >
                <p class="s-text-primary">{{ fileDoc.name }}</p>
                <img
                  :src="require('@/assets/icons/close-primary.svg')"
                  class="s-ml-auto"
                  @click="fileDoc = {}"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click.prevent="onFinish"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form, common],
  data() {
    return {
      edited_item: {
        expertise_detail: [],
        institution: "",
        position: "",
      },

      validation: {
        expertise_detail: false,
        institution: false,
        position: false,
      },
      fileDoc: {},
      // empty_field: false,
      isLoading: false,
    };
  },
  created() {
    this.initData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      user: "auth/getUser",
    }),
  },
  methods: {
    ...mapActions({
      postRequest: "admin/postRequestUpgrade",
      setExpertise: "expertise/setExpertiseDropdownData",
      setUserData: "auth/setUserData",
    }),
    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      this.setLoadingPage(false);
    },
    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("id", this.user.id);
        formData.append("file", this.fileDoc);
        formData.append("institution", this.edited_item.institution);
        formData.append("position", this.edited_item.position);
        for (var i = 0; i < this.edited_item.expertise_detail.length; i++) {
          formData.append(
            "expertise_detail_id[]",
            this.edited_item.expertise_detail[i]
          );
        }

        await this.postRequest(formData);
        if (this.$store.getters["admin/getStatus"] == 1) {
          this.$router.push(
            this.$translate({
              name: "Dashboard CMS",
            })
          );
        } else {
          this.showSnackbar({
            type: "error",
            text: this.$t("general.requestFailed"),
          });
        }
      }
      this.isLoading = false;
    },
    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Dashboard CMS",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>